<template>
  <div class="personal_result">
    <div class="personal_result-box">
    <div class="personal_icon ">
      <div>
      <img :src="state_data.img" alt="">
      </div>
    </div>
    <p class="personal-title">{{state_data.title}}</p>
    <p class="personal-info" v-if="userInfo.verifyFailedReason && this.userInfo.verifyStatus==='4'">驳回原因：{{userInfo.verifyFailedReason}}</p>

    <div class="personal_table">
      <div class="title">您的认证信息</div>
      <div>
        <p>证件类型</p>
        <p>中华人民共和国居民身份证</p>
      </div>
      <div>
        <p>姓名</p>
        <p>{{userInfo.realName}}</p>
      </div>
      <div>
        <p>证件号码</p>
        <p>{{userInfo.citizenIdNo | capitalize}}</p>
      </div>
      <div>
        <p>提交时间</p>
        <p>{{userInfo.applyTime}}</p>
      </div>
    </div>

    <van-button class="btn" color="#EA0B06" plain  block @click="$router.push('/verified_personal')">修改实名认证</van-button>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'personal_result',
  data () {
    return {
      err_data: {
        img: require('@/assets/img/personal_result1.png'),
        title:'实名认证失败',
      },
      while_data:{
        img: require('@/assets/img/personal_result2.png'),
        title:'实名认证中',
      },
      res_data:{
        img: require('@/assets/img/personal_result.png'),
        title:'实名认证成功',
      }
    }
  },
  computed:{
    ...mapGetters(["userInfo"]),
    state_data(){
      let map={'2':this.while_data,'3':this.res_data,'4':this.err_data}
      return map[this.userInfo.verifyStatus]
    }
  },
  mounted () {
    document.title = '实名认证'
    this.GetInfo();
  },
  methods:{
    ...mapActions(["GetInfo"]),
  },
}
</script>

<style scoped lang="scss">
.personal_result {
  height: 100vh;
  width: 100vw;
  background: #F6F6F6;
  padding: 72px 16px 0 16px;
  .personal_icon{
    height: 78px;
    margin-bottom: 24px;
    >div{
      margin: 0 auto;
      max-width: 78px;
      max-height: 78px;
      >img{
        width: 100%;
        height: 100%;
      }
    }
  }

  .personal-title{
    text-align: center;
    font-size: 24px;
    //font-weight: 500;
    line-height: 30px;
    color: #262626;
  }

  .personal-info{
    margin: 12px 16px 0;
    font-size: 14px;
    //font-weight: 500;
    line-height: 21px;
    color: #999999;
  }

  .personal_table{
    width: 343px;
    height: 196px;
    background: #FFFFFF;
    border: 1px solid #D4D4D4;
    border-radius: 4px;
    padding: 0 16px;
    margin-top: 24px;
    margin-bottom: 16px;
    >.title{
      height: 51px;
      font-size: 14px;
      //font-weight: 500;
      line-height: 51px;
      color: #262626;
      border-bottom: #EEEEEE solid 1px;
      margin-bottom: 12px;
    }
    &>:not(:first-child){
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      &>:first-child{
        font-size: 14px;
        //font-weight: 500;
        color: #999999;
      }
      &>:last-child{
        font-size: 14px;
        //font-weight: 500;
        color: #262626
      }
    }
  }


  .btn{

  }
}
</style>
